export const GUEST_FEEDBACK_AND_REVIEW_REPLIES =
  `
Efficiently manage and respond to guest feedback and reviews using our intelligent reply generator. Enhance customer satisfaction by providing personalized and thoughtful responses to their comments.

## Features

### Automated Reply Generation

- **Description:** Quickly generate appropriate and personalized replies to guest reviews.
- **How it works:** Our AI analyzes the review content and suggests a reply that addresses the guest's comments, concerns, or praises.
- **Example:** A guest praises the cleanliness of your hotel. The AI suggests a reply thanking them for their feedback and inviting them to stay again.

### Sentiment Analysis

- **Description:** Understand the sentiment behind each review to tailor your responses effectively.
- **How it works:** The system categorizes reviews as positive, negative, or neutral, allowing you to prioritize and customize your responses.
- **Example:** A negative review about slow service prompts a reply acknowledging the issue and promising improvement.

### Template Audiences

- **Description:** Access a library of customizable reply templates tailored to different audience segments.
- **How it works:** Choose from a variety of templates designed for specific types of feedback and audience groups, ensuring consistent and professional replies.
- **Example:** A template for thanking families for their positive feedback, with placeholders for personalizing the response to include family-friendly amenities and activities.

### Audience Segments:

1. **Luxury Travelers:** Templates focus on high-end amenities, exclusive experiences, and personalized services.
2. **Family Road Trippers:** Templates highlight family-friendly facilities, road trip tips, and activities for all ages.
3. **Foodies:** Templates emphasize local cuisine, dining experiences, and special food-related events.
4. **Multi-gen Travelers:** Templates cater to activities and services that appeal to multiple generations, from grandparents to grandchildren.
5. **Marketing Executive:** Templates highlight business facilities, corporate packages, and convenience for work-related stays.
6. **Asset Manager:** Templates focus on investment in property improvements, ROI, and asset management strategies.
7. **Baseline Audience:** General templates that are versatile and can be customized for any type of guest feedback.

### Customization Options

- **Description:** Customize the generated replies to add a personal touch.
- **How it works:** Edit the AI-suggested reply to include specific details or additional information before sending.
- **Example:** Adding information about a new service or upcoming event in your reply to a positive review.

## Use Case

**Scenario:** A guest leaves a review praising the hotel's cleanliness but mentions slow check-in service.

**Step 1:** The sentiment analysis categorizes the review as mixed.

**Step 2:** The AI generates a reply thanking the guest for their positive comments and addressing the slow check-in issue with an apology and assurance of improvement.

**Step 3:** Customize the reply to include a special offer for the guest's next stay as an apology.

**Step 4:** Send the reply and track the guest's response.

## Get Started Now

Enhance your guest engagement by responding swiftly and effectively to their feedback. Start using the Guest Feedback and Review Replies feature today and see the difference in your customer satisfaction levels.
`

export const MARKETING_SURVEY = `
The Marketing Survey feature allows you to create and deploy surveys tailored to gather valuable insights from our audience. Whether you're looking to understand customer preferences, gauge satisfaction levels, or collect market data, this tool empowers you to make informed decisions based on comprehensive feedback.

## Features

### Survey Creation Wizard

- **Description:** Easily create customized surveys using our intuitive survey creation wizard.
- **How it works:** Choose from various question types, add branching logic, and personalize survey themes to match your brand identity.
- **Example:** Design a survey to gather feedback on recent product launches, with questions tailored to assess user experience and satisfaction.

### Audience Targeting

- **Description:** Define target audiences based on demographics, behaviors, or preferences.
- **How it works:** Segment your audience to ensure surveys reach the right people, optimizing response rates and data relevance.
- **Example:** Target specific customer segments such as frequent buyers, first-time visitors, or subscribers to tailor survey questions accordingly.

## Example Use Case

**Scenario:** A company wants to launch a new product and needs feedback on potential features and pricing.

**Step 1:** Create a survey with questions about product preferences, pricing expectations, and purchase intent.

**Step 2:** Target the survey to existing customers and prospects interested in similar product categories.

**Step 3:** Integrate survey insights with CRM data to tailor marketing campaigns and promotions effectively.

## Get Started Now

Harness the power of customer feedback to refine your marketing strategies and drive business growth. Start using the Marketing Survey feature today to gain valuable insights and enhance customer engagement.
`

export const COMPETITOR_ANALYSIS = `
Perform thorough competitor analysis to gain valuable insights into market trends, strategies, and positioning. Our tools provide you with the data and intelligence needed to make informed decisions and stay ahead of the competition.

## Features

### Competitive Landscape Mapping

- **Description:** Visualize the competitive landscape with detailed mapping of key competitors.
- **How it works:** Identify direct and indirect competitors, their market share, product offerings, and pricing strategies.
- **Example:** Compare your product range and pricing against competitors to identify competitive advantages.

### Market Intelligence Reports

- **Description:** Access comprehensive reports on industry trends, market dynamics, and competitor performance.
- **How it works:** Analyze data to understand market positioning, customer preferences, and emerging opportunities.
- **Example:** Review quarterly reports on competitor market share changes and adjust strategies accordingly.

### SWOT Analysis

- **Description:** Conduct SWOT (Strengths, Weaknesses, Opportunities, Threats) analysis for competitive insights.
- **How it works:** Evaluate internal strengths and weaknesses against external opportunities and threats posed by competitors.
- **Example:** Identify areas where competitors excel and areas where your business can capitalize.

### Pricing and Product Comparison

- **Description:** Compare pricing strategies and product features across competitors.
- **How it works:** Analyze price points, promotional strategies, and product differentiation to optimize pricing strategies.
- **Example:** Benchmark your product features and pricing against competitors to identify opportunities for improvement.

## Example Use Case

**Scenario:** A new competitor enters your market segment with a disruptive pricing strategy.

**Step 1:** Analyze the competitor's pricing model and product features using the competitive landscape mapping tool.

**Step 2:** Conduct a SWOT analysis to identify potential threats and opportunities posed by the new competitor.

**Step 3:** Adjust your pricing strategy and marketing tactics based on the insights gathered to maintain competitiveness.

## Get Started Now

Gain a strategic advantage in your industry by leveraging comprehensive competitor analysis. Start using the Competitor Analysis feature today to stay informed and make proactive business decisions.
  `
export const SALES_AND_PROSPECTS_MINING = `
Unlock new sales opportunities and optimize your prospecting efforts with advanced data mining tools. This feature empowers you to identify and prioritize potential leads, streamline sales processes, and maximize conversion rates.

## Features

### Lead Identification

- **Description:** Identify potential leads and prospects based on predefined criteria and data analysis.
- **How it works:** Utilize data mining techniques to pinpoint prospects who match ideal customer profiles.
- **Example:** Identify businesses in specific industries or geographical regions likely to benefit from your products or services.

### Prospect Scoring

- **Description:** Score prospects based on their likelihood to convert into customers.
- **How it works:** Evaluate factors such as engagement history, demographics, and firmographics to prioritize leads.
- **Example:** Prioritize high-scoring prospects for personalized outreach and targeted marketing campaigns.

### Opportunity Analysis

- **Description:** Analyze sales opportunities and forecast potential revenue.
- **How it works:** Assess the size, scope, and timing of potential deals to allocate resources effectively.
- **Example:** Evaluate the profitability and strategic fit of each sales opportunity to optimize sales efforts.

### Performance Metrics

- **Description:** Track and analyze key performance indicators (KPIs) related to sales and prospecting.
- **How it works:** Monitor metrics such as conversion rates, pipeline velocity, and sales cycle length to measure success.
- **Example:** Use data-driven insights to refine sales strategies and improve overall performance.

## Example Use Case

**Scenario:** A company launches a new product and needs to identify and prioritize leads for targeted outreach.

**Step 1:** Use data mining tools to identify businesses in relevant industries likely to benefit from the new product.

**Step 2:** Score prospects based on their purchasing behavior and engagement with similar products.

**Step 3:** Analyze the potential revenue and strategic fit of each opportunity to focus sales efforts effectively.

## Get Started Now

Optimize your sales pipeline and increase revenue by leveraging advanced data mining tools. Start using the Sales and Prospects Mining feature today to identify, prioritize, and convert leads effectively.
  `

export const BUILD_A_WEBSITE_USER_JOURNEYS = `
Craft personalized user journeys on your website to enhance user experience and maximize conversions. This feature empowers you to analyze website content, create tailored pathways, and optimize user engagement through bespoke experiences.

## Features

### Content Analysis and Customization

- **Description:** Analyze website content to identify key themes and user interests.
- **How it works:** Extract relevant content elements such as products, services, and features to create personalized user journeys.
- **Example:** Tailor user experiences by highlighting specific product benefits or service offerings based on visitor interests.

### Journey Creation with Landing Page Components

- **Description:** Design user journeys using customizable landing page components.
- **How it works:** Build pathways that guide users through stages such as awareness, consideration, and conversion using interactive elements.
- **Example:** Create a landing page with dynamic components like sliders, interactive forms, and personalized recommendations to engage users effectively.

### Personalization and Dynamic Content Delivery

- **Description:** Deliver personalized content based on user behavior, preferences, and demographic data.
- **How it works:** Customize user experiences in real-time by adjusting content based on visitor interactions and profile insights.
- **Example:** Display targeted offers, promotions, or content blocks that resonate with each user's unique needs and interests.

## Example Use Case

**Scenario:** An e-commerce website wants to increase sales of a new product line.

**Step 1:** Analyze website data to understand visitor preferences and behaviors related to the new products.

**Step 2:** Design a landing page featuring the new product line with interactive elements such as product sliders and customer testimonials.

**Step 3:** Personalize the user journey by dynamically displaying related products based on user browsing history and preferences.

## Get Started Now

Enhance user engagement and drive conversions by creating personalized user journeys tailored to your website's content and audience preferences. Start using the Build a Website User Journeys feature today to deliver compelling online experiences and achieve business growth.
  `

export const CHAT_AND_INTERACT_WITH_DATA_AND_DOCUMENTS = `
Facilitate interactive communication and data exploration through chat interfaces integrated with document management capabilities. This feature empowers users to collaborate effectively, retrieve information, and interact with documents seamlessly.

## Features

### Chat Interface

- **Description:** Engage in real-time conversations and queries using a chatbot or interactive chat interface.
- **How it works:** Users can ask questions, retrieve data, and receive automated responses or guided interactions.
- **Example:** Ask about sales figures for the past quarter or request documents related to a specific project.

### Document Management

- **Description:** Store, organize, and retrieve documents securely within the platform.
- **How it works:** Upload files and access them easily during chat interactions.
- **Example:** Share product manuals, policies, or reports directly through the chat interface.

### Natural Language Processing (NLP)

- **Description:** Utilize NLP capabilities to interpret and respond to user queries and commands.
- **How it works:** Analyze natural language inputs to understand user intent and provide relevant information or actions.
- **Example:** Interpret complex queries about financial data or legal documents to retrieve specific information.

### Collaboration Tools

- **Description:** Collaborate with team members during chat sessions.
- **How it works:** Share documents, annotate files, and discuss insights or findings directly within the chat interface.
- **Example:** Ask questions, review documents, and make decisions collaboratively.

## Example Use Case

**Scenario:** A project team needs to review progress reports and analyze financial data during a virtual meeting.

**Step 1:** Initiate a chat session to retrieve the latest progress reports and financial statements.

**Step 2:** Use chat to query specific data points or request additional information.

**Step 3:** Discuss findings, annotate documents, and make decisions collaboratively with team members.

## Get Started Now

Enhance collaboration and streamline information retrieval with interactive chat and document management capabilities. Start using the Chat and Interact with Data and Documents feature today to improve productivity and decision-making.
`