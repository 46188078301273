import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import UpdateCompanyDialog from "./UpdateCompanyDialog";
import axios from "axios";
import {COMPANIES_URL} from "../constants/endpoints";
import {useNotification} from "../contexts/NotificationContext";
import Cookies from "js-cookie";
import {LOGIN_ERROR_MESSAGES} from "../constants/errorMessages";
import {TreeNode} from "./TreeNode";


const Tree = ({data, organizations, setOrganizations, formMode, setFormMode}) => {

  const [treeData, setTreeData] = useState([]);
  const [isOrgEditing, setIsOrgEditing] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [isLoadingUpdateOrg, setIsLoadingUpdateOrg] = useState(false);
  const {setIsOpen, setMessage, setIsFailed} = useNotification();
  const [indexToDelete, setIndexToDelete] = useState(0);
  const token = Cookies.get("token");

  useEffect(() => {
    setTreeData(buildTree(data));
  }, [organizations])

  async function handleUpdateOrg() {
    setIsLoadingUpdateOrg(true);
    const currentName = organizations.find(org => org.id === orgData.id).name;
    try {
      if (!orgData.name) {
        setIsOpen(true);
        setIsFailed(true);
        setMessage("Name is required");
      }
      if (!orgData.zone) {
        setIsOpen(true);
        setIsFailed(true);
        setMessage("Timezone is required");
      }
      console.log("Org data:", orgData)
      if (orgData.name && orgData.zone) {
        const response = await axios.put(
          `${COMPANIES_URL}/${orgData.id}`,
          orgData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            },
          });
        if (response.status === 401 || response.status === 400 || response.status === 409) {
          throw new Error(response.status);
        }
        const state = [...organizations];
        state.splice(indexToDelete, 1);
        setOrganizations([...state, response.data]);
        if (currentName === formMode) {
          setFormMode(orgData.name);
        }
        setIsOrgEditing(false);
        setIsOpen(true);
        setIsFailed(false);
        setMessage("Organization is updated successfully");
      }
    } catch (error) {
      let errorMessage =
        LOGIN_ERROR_MESSAGES[error.message]
        || LOGIN_ERROR_MESSAGES[error.response.status]
        || LOGIN_ERROR_MESSAGES.default;
      setIsOpen(true);
      setIsFailed(true);
      setMessage(errorMessage);
    } finally {
      setIsLoadingUpdateOrg(false);
    }
  }

  const buildTree = (data) => {
    const map = new Map(data.map(node => [node.id, {...node, children: []}]));
    data.forEach(node => {
      if (node.parent) {
        const parent = map.get(node.parent.id);
        if (parent) {
          parent.children.push(map.get(node.id));
        }
      }
    });
    const rootNodes = Array.from(map.values()).filter(node => !node.parent);
    return rootNodes.length > 0 ? rootNodes : data;
  };

  return (
    <Box sx={{
      padding: '20px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    }}>
      {treeData.length > 0 ? (
        treeData.map(rootNode => (
          <TreeNode key={rootNode.id} node={rootNode} setIsOrgEditing={setIsOrgEditing}
                    setOrgData={setOrgData}
                    setIndexToDelete={setIndexToDelete}/>
        ))
      ) : (
        <Typography>No data available</Typography>
      )}
      <UpdateCompanyDialog
        handleSubmit={handleUpdateOrg}
        orgData={orgData}
        setOrgData={setOrgData}
        handleClose={() => {
          setOrgData({});
          setIsOrgEditing(false);
        }}
        isOpened={isOrgEditing}
        isLoading={isLoadingUpdateOrg}
      />
    </Box>
  );
};

export default Tree;